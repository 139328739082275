import React, { useState } from 'react';
import { Layout, theme } from 'antd';
import NavigationHeader from '../../components/NavigationHeader';
import { Content } from 'antd/es/layout/layout';
import Sider from 'antd/es/layout/Sider';
import SidebarSearchView from './SearchSideBar';
import DictionaryTabView from './DictionaryTabView';
import { DictionaryEntry } from '../../interfaces/DictionaryEntry';
import { fetchDictionaryEntry } from '../../services/dictionaryService';
import {fetchExampleSentences} from "../../services/languageToolService";

const { useToken } = theme;

const DictionaryPage: React.FC = () => {
    const { token } = useToken();
    const [entries, setEntries] = useState<DictionaryEntry[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const handleLookup = async (word: string) => {
        word = word.trim()
        setLoading(true);
        setError(null);
        try {
            const newEntry = await fetchDictionaryEntry(word);
            const sentencePairResponse = await fetchExampleSentences(word);
            newEntry.exampleSentences = sentencePairResponse.sentence_pairs;
            setEntries(prevEntries => [...prevEntries, newEntry]);
        } catch (error) {
            setError('Error fetching dictionary entry');
        } finally {
            setLoading(false);
        }
    };

    const handleRemoveEntry = (targetIndex: number) => {
        setEntries(prevEntries => prevEntries.filter((_, index) => index !== targetIndex));
    };

    return (
        <Layout>
            <NavigationHeader selectedKey={'Dictionary'}/>
            <Content style={{ padding: '24px 48px' }}>
                <Layout
                    style={{ padding: '0px 24px', background: token.colorBgContainer, borderRadius: token.borderRadiusLG }}
                >
                    <Sider style={{ padding: '24px 0px', background: token.colorBgContainer }} width={300}>
                        <SidebarSearchView onLookup={handleLookup} />
                    </Sider>

                    <Content style={{ padding: '0 24px', minHeight: 600, background: 'white' }}>
                        {loading && <div>Loading...</div>}
                        {error && <div>{error}</div>}
                        <DictionaryTabView entries={entries} onRemove={handleRemoveEntry} />
                    </Content>
                </Layout>
            </Content>
        </Layout>
    );
};

export default DictionaryPage;
