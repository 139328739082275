import React, { useState } from "react";
import { Layout, Card, Button, List, Typography, Space, Tooltip, Tag, Input, AutoComplete } from "antd";
import { EyeOutlined, SaveOutlined, ShareAltOutlined, CloseOutlined } from '@ant-design/icons';
import NavigationHeader from "../../components/NavigationHeader";
import { Content } from "antd/es/layout/layout";
import { useNavigate } from 'react-router-dom';


const { Title, Text } = Typography;
const { Search } = Input;

const vocabularySets = [
    {
        title: 'HSK 1 Vocabulary',
        description: 'Must know words to be successful',
        previewWords: ['你好', '谢谢', '再见'],
        author: 'John Doe',
        creationDate: '2023-09-01',
        views: 152,
        tags: ['Beginner', 'HSK1'],
    },
    {
        title: 'Daily Conversation',
        description: 'Words you might hear in real life.',
        previewWords: ['吃饭', '睡觉', '工作'],
        author: 'Jane Smith',
        creationDate: '2023-08-15',
        views: 98,
        tags: ['Conversation', 'Beginner'],
    },
    {
        title: 'Travel Vocabulary',
        description: 'Words that will be useful when going traveling',
        previewWords: ['机场', '火车站', '酒店'],
        author: 'Alice Lee',
        creationDate: '2023-09-10',
        views: 230,
        tags: ['Travel', 'Vacation']
    },
];

// Extract all unique tags from vocabularySets for the AutoComplete options
const allTags = Array.from(new Set(vocabularySets.flatMap(set => set.tags)));

const VocabularySetsPage: React.FC = () => {
    const [searchValue, setSearchValue] = useState('');
    const [filteredSets, setFilteredSets] = useState(vocabularySets);
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const [autoCompleteOptions, setAutoCompleteOptions] = useState(allTags); // Store options
    const [selectedIndex, setSelectedIndex] = useState(0); // Track the current index of the autocomplete options
    const navigate = useNavigate();

    const handleCreateNewSet = () => {
        navigate('/vocabulary-sets/create');
    };

    const handleSearch = (value: string) => {
        setSearchValue(value); // Update the search input value

        if (!value) {
            return;
        }

        // Add tag to the selected tags list if not already present
        if (!selectedTags.includes(value)) {
            const newTags = [...selectedTags, value];
            setSelectedTags(newTags);
            filterVocabularySets(newTags); // Filter vocabulary sets with the updated selected tags
        }

        setSearchValue(''); // Clear search input
        setSelectedIndex(0); // Reset the selected index when a tag is added
    };

    const handleTagClose = (tag: string) => {
        // Remove the tag from the selectedTags list
        const newTags = selectedTags.filter(t => t !== tag);
        setSelectedTags(newTags);
        filterVocabularySets(newTags);
    };

    const filterVocabularySets = (tags: string[]) => {
        if (tags.length === 0) {
            setFilteredSets(vocabularySets); // Show all sets when no tags are selected
            return;
        }

        // Filter vocabulary sets based on selected tags
        const filtered = vocabularySets.filter(set =>
            tags.every(tag =>
                set.tags.some(t => t.toLowerCase().includes(tag.toLowerCase()))
            )
        );
        setFilteredSets(filtered);
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Tab' && autoCompleteOptions.length > 0) {
            e.preventDefault(); // Prevent default tab behavior
            const nextIndex = (selectedIndex + 1) % autoCompleteOptions.length; // Cycle through options
            setSelectedIndex(nextIndex);
            setSearchValue(autoCompleteOptions[nextIndex]); // Set input value to the next suggestion
        }
    };

    const handleAutoCompleteSearch = (value: string) => {
        setSearchValue(value);
        const filteredOptions = allTags.filter(tag =>
            tag.toLowerCase().includes(value.toLowerCase())
        );
        setAutoCompleteOptions(filteredOptions); // Update the options based on input
        setSelectedIndex(0); // Reset the index when the input changes
    };

    return (
        <Layout>
            <NavigationHeader selectedKey={'vocabulary-sets'} />

            <Content style={{ padding: '24px 48px' }}>
                <div style={{ marginBottom: '20px' }}>
                    <Space size="middle" align="center">
                        <Button type="primary" onClick={handleCreateNewSet}>
                            Create New Vocabulary Set
                        </Button>

                        <AutoComplete
                            style={{ width: 300 }}
                            options={autoCompleteOptions.map(tag => ({ value: tag }))} // Use the filtered options
                            placeholder="Search by tags"
                            allowClear
                            value={searchValue} // Bind search input value to state
                            onSelect={handleSearch} // Trigger when a tag is selected
                            onSearch={handleAutoCompleteSearch} // Update the search and options
                            onKeyDown={handleKeyDown} // Handle Tab key press
                        >
                            <Search enterButton onSearch={() => handleSearch(searchValue)} />
                        </AutoComplete>

                        {/* Display selected tags with a close button */}
                        <div>
                            {selectedTags.map(tag => (
                                <Tag
                                    key={tag}
                                    closable
                                    onClose={() => handleTagClose(tag)}
                                    style={{ marginBottom: '8px' }}
                                >
                                    {tag}
                                </Tag>
                            ))}
                        </div>
                    </Space>
                </div>

                <List
                    grid={{ gutter: 16, column: 3 }}
                    dataSource={filteredSets}
                    renderItem={set => (
                        <List.Item>
                            <Card
                                title={
                                    <div style={{ padding: '12px 0' }}>
                                        <Title level={4} style={{ fontWeight: 'bold', marginBottom: '4px' }}>
                                            {set.title}
                                        </Title>
                                        <Text type="secondary" style={{ marginBottom: '0' }}>{set.description}</Text>
                                    </div>
                                }
                                hoverable
                                style={{
                                    borderRadius: '8px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                    transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                                }}
                                onMouseEnter={e => {
                                    e.currentTarget.style.transform = 'scale(1.05)';
                                    e.currentTarget.style.boxShadow = '0 6px 16px rgba(0, 0, 0, 0.2)';
                                }}
                                onMouseLeave={e => {
                                    e.currentTarget.style.transform = 'scale(1)';
                                    e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
                                }}
                                extra={
                                    <Space>
                                        <Tooltip title="Save">
                                            <Button shape="circle" icon={<SaveOutlined />} />
                                        </Tooltip>
                                        <Tooltip title="Share">
                                            <Button shape="circle" icon={<ShareAltOutlined />} />
                                        </Tooltip>
                                    </Space>
                                }
                            >
                                <div style={{ paddingLeft: '16px', marginTop: '8px' }}>
                                    <ul style={{
                                        paddingLeft: '0',
                                        listStyleType: 'none',
                                        fontSize: '20px',
                                        color: '#595959',
                                        lineHeight: '1.8'
                                    }}>
                                        {set.previewWords.map(word => (
                                            <li key={word}>{word}</li>
                                        ))}
                                    </ul>

                                    <div style={{ marginTop: '16px', color: '#8c8c8c' }}>
                                        <Text>By: <strong>{set.author}</strong></Text><br />
                                        <Text>Last Updated: {new Date(set.creationDate).toLocaleDateString()}</Text><br />
                                        <Space>
                                            <EyeOutlined />
                                            <Text>{set.views} views</Text>
                                        </Space>
                                    </div>

                                    {/* Render tags below the card */}
                                    <div style={{ marginTop: '12px' }}>
                                        {set.tags.map(tag => (
                                            <Tag key={tag} color="blue" style={{ marginBottom: '8px' }}>
                                                {tag}
                                            </Tag>
                                        ))}
                                    </div>
                                </div>
                            </Card>
                        </List.Item>
                    )}
                />
            </Content>
        </Layout>
    );
};

export default VocabularySetsPage;
