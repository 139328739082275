import React, {useEffect, useRef, useState} from "react";
import HanziWriter from "hanzi-writer";
import {theme} from "antd";

interface CharacterStrokeOrderProps {
    character: string;
}

const {useToken} = theme;
const CharacterStrokeOrder: React.FC<CharacterStrokeOrderProps> = ({ character }) => {
    const { token } = useToken();
    const characterContainer = useRef<HTMLDivElement | null>(null);
    const [writer, setWriter] = useState<HanziWriter>();

    useEffect(() => {
        if (characterContainer.current) {
            const writer = HanziWriter.create(characterContainer.current, character, {
                width: 100,
                height: 100,
                padding: 0,
                radicalColor: '#F00',
                showOutline: true
            });
            setWriter(writer);
        }
    }, [character]);

    return (
        <div onClick={() => writer && writer.animateCharacter()} style={{border: "1px solid black"}} ref={characterContainer}></div>
    );
};

export default CharacterStrokeOrder;
