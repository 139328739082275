import React, {useEffect} from "react";
import {BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom";
import {ConfigProvider} from "antd";
import DictionaryPage from "./pages/dictionary/DictionaryPage";
import CharactersPage from "./pages/characters/CharactersPage";
import IdiomsPage from "./pages/idioms/IdiomsPage";
import TextAnalyzer from "./pages/sentence-analyzer/TextAnalyzer";
import VocabularySetsPage from "./pages/vocabulary-sets/VocabularySetsPage";
import CreateVocabularySetPage from "./pages/vocabulary-sets/CreateVocabularySetPage";

const App: React.FC = () => {
    useEffect(() => {
        document.title = "Modern Chinese Dictionary";
    }, []);

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#f8a175',
                    colorInfo: '#f8a175',
                    fontSize: 18,
                    borderRadius: 6,
                },
            }}
        >
            <Router>
                <Routes>
                    <Route path="/" element={<Navigate to="/dictionary" />} />
                    <Route path="/dictionary" element={<DictionaryPage />} />
                    <Route path="/text-analyzer" element={<TextAnalyzer/> } />
                    <Route path="/characters" element={<CharactersPage />} />
                    <Route path="/idioms" element={<IdiomsPage />} />
                    <Route path="/vocabulary-sets" element={<VocabularySetsPage/>} />
                    <Route path="/vocabulary-sets/create" element={<CreateVocabularySetPage />} /> {/* New Route */}

                </Routes>
            </Router>
        </ConfigProvider>
    );
};

export default App;
