import React, { useState } from 'react';
import { Form, Input, Button, Space, Card, List, Typography, Layout, Row, Col, Divider, Tag, Steps } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import NavigationHeader from "../../components/NavigationHeader";
import { Content } from "antd/es/layout/layout";

const { TextArea } = Input;
const { Title, Text } = Typography;
const { Step } = Steps;

interface VocabularyWord {
    word: string;
    pinyin: string;
    definition: string;
}

interface VocabularySetDetails {
    title: string;
    description: string;
    tags: string[];
}

const CreateVocabularySetPage: React.FC = () => {
    const [vocabList, setVocabList] = useState<VocabularyWord[]>([]);
    const [newWord, setNewWord] = useState<VocabularyWord>({ word: '', pinyin: '', definition: '' });
    const [setDetails, setSetDetails] = useState<VocabularySetDetails>({ title: '', description: '', tags: [] });
    const [newTag, setNewTag] = useState('');
    const [inputVisible, setInputVisible] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);

    const handleAddWord = () => {
        if (newWord.word && newWord.pinyin && newWord.definition) {
            setVocabList([...vocabList, newWord]);
            setNewWord({ word: '', pinyin: '', definition: '' });
            setCurrentStep(2); // Progress to next step after adding a word
        }
    };

    const handleFormChange = (key: keyof VocabularyWord, value: string) => {
        setNewWord({ ...newWord, [key]: value });
    };

    const handleSetDetailsChange = (key: keyof VocabularySetDetails, value: string) => {
        setSetDetails({ ...setDetails, [key]: value });
    };

    const handleTagInputConfirm = () => {
        if (newTag && !setDetails.tags.includes(newTag)) {
            setSetDetails({ ...setDetails, tags: [...setDetails.tags, newTag] });
        }
        setNewTag('');
        setInputVisible(false);
    };

    const removeTag = (removedTag: string) => {
        const updatedTags = setDetails.tags.filter(tag => tag !== removedTag);
        setSetDetails({ ...setDetails, tags: updatedTags });
    };

    const handleSaveAndNextStep = () => {
        if (setDetails.title && setDetails.description) {
            setCurrentStep(1); // Progress to the next step
        }
    };

    return (
        <Layout>
            <NavigationHeader selectedKey={'create-vocab-set'} />
            <Content style={{ padding: '40px', backgroundColor: '#f5f5f5', minHeight: '100vh' }}>
                <Card bordered={false} style={{ borderRadius: '8px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)', marginBottom: '20px' }}>
                    <Row gutter={24}>
                        {/* Left Side: Page Title and Progress Bar */}
                        <Col span={12}>
                            <Title level={4}>Create New Vocabulary Set</Title>
                            <Steps size="small" current={currentStep} style={{ marginTop: '20px' }}>
                                <Step title="Set Details" description="Enter title, description, and tags" />
                                <Step title="Add Words" description="Add Chinese words, pinyin, and definitions" />
                                <Step title="Review & Save" description="Review and save your vocabulary set" />
                            </Steps>
                        </Col>

                        {/* Right Side: Title, Description, and Save Button */}
                        <Col span={12}>
                            <Form layout="vertical">
                                <Form.Item label={<Text strong>Set Title</Text>} style={{ marginBottom: '12px' }}>
                                    <Input
                                        value={setDetails.title}
                                        onChange={(e) => handleSetDetailsChange('title', e.target.value)}
                                        placeholder="Enter set title"
                                        style={{ borderRadius: '6px' }}
                                    />
                                </Form.Item>

                                <Form.Item label={<Text strong>Set Description</Text>} style={{ marginBottom: '12px' }}>
                                    <TextArea
                                        value={setDetails.description}
                                        onChange={(e) => handleSetDetailsChange('description', e.target.value)}
                                        rows={2}
                                        placeholder="Enter set description"
                                        style={{ borderRadius: '6px' }}
                                    />
                                </Form.Item>

                                <Button type="primary" onClick={handleSaveAndNextStep} block>
                                    Save and Continue
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Card>

                <Divider style={{ margin: '20px 0' }} />

                <Row gutter={32}>
                    {/* Left pane for form */}
                    <Col span={12}>
                        <Card bordered={false} style={{ borderRadius: '8px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}>
                            <Form layout="vertical">
                                <Title level={4}>Add Words to Set</Title>
                                <Form.Item label={<Text strong>Word</Text>}>
                                    <Input
                                        value={newWord.word}
                                        onChange={(e) => handleFormChange('word', e.target.value)}
                                        placeholder="Enter Chinese word"
                                        style={{ borderRadius: '6px' }}
                                    />
                                </Form.Item>

                                <Form.Item label={<Text strong>Pinyin</Text>}>
                                    <Input
                                        value={newWord.pinyin}
                                        onChange={(e) => handleFormChange('pinyin', e.target.value)}
                                        placeholder="Enter pinyin"
                                        style={{ borderRadius: '6px' }}
                                    />
                                </Form.Item>

                                <Form.Item label={<Text strong>Definition</Text>}>
                                    <TextArea
                                        value={newWord.definition}
                                        onChange={(e) => handleFormChange('definition', e.target.value)}
                                        rows={2}
                                        placeholder="Enter definition"
                                        style={{ borderRadius: '6px' }}
                                    />
                                </Form.Item>

                                <Button type="primary" onClick={handleAddWord} block style={{ borderRadius: '6px', marginTop: '20px' }}>
                                    Add Word
                                </Button>
                            </Form>
                        </Card>
                    </Col>

                    {/* Right pane for displaying vocab list */}
                    <Col span={12}>
                        <Card bordered={false} style={{ borderRadius: '8px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}>
                            <Title level={4}>Current Words in Set</Title>
                            <List
                                grid={{ gutter: 16, column: 1 }}
                                dataSource={vocabList}
                                renderItem={(item) => (
                                    <List.Item>
                                        <Card bordered={false} style={{ borderRadius: '6px', background: '#fafafa' }}>
                                            <Space direction="vertical">
                                                <Text><strong>Word:</strong> {item.word}</Text>
                                                <Text><strong>Pinyin:</strong> {item.pinyin}</Text>
                                                <Text><strong>Definition:</strong> {item.definition}</Text>
                                            </Space>
                                        </Card>
                                    </List.Item>
                                )}
                            />
                            <Button type="primary" block style={{ borderRadius: '6px', marginTop: '20px' }}>
                                Save Vocabulary Set
                            </Button>
                        </Card>
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
};

export default CreateVocabularySetPage;
