import React, { useState } from "react";
import { Button, Empty, Layout, Space, Row, Col, theme, Drawer } from "antd";
import { SearchOutlined } from "@ant-design/icons"; // Import the magnifying glass icon
import NavigationHeader from "../../components/NavigationHeader";
import { Content } from "antd/es/layout/layout";
import DictionaryTabView from "../dictionary/DictionaryTabView";
import { DictionaryEntry } from "../../interfaces/DictionaryEntry";
import { fetchExampleSentences, fetchSegmentedText } from "../../services/languageToolService";
import { fetchDictionaryEntry } from "../../services/dictionaryService";
import TextArea from "antd/es/input/TextArea";

const { useToken } = theme;

const TextAnalyzer: React.FC = () => {
    const { token } = useToken();
    const [text, setText] = useState("");
    const [segmentedText, setSegmentedText] = useState<string[]>([]);
    const [entries, setEntries] = useState<DictionaryEntry[]>([]);
    const [drawerVisible, setDrawerVisible] = useState(false);

    const handleTextChange = async (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setText(e.target.value);
    };

    const handleLookup = async (word: string) => {
        word = word.trim();
        try {
            const newEntry = await fetchDictionaryEntry(word);
            const sentencePairResponse = await fetchExampleSentences(word);
            newEntry.exampleSentences = sentencePairResponse.sentence_pairs;
            setEntries(prevEntries => [...prevEntries, newEntry]);
        } catch (error) {
            // Handle error if necessary
        }
    };

    const handleRemoveEntry = (targetIndex: number) => {
        setEntries(prevEntries => prevEntries.filter((_, index) => index !== targetIndex));
    };

    const handleTextSubmission = async () => {
        const segmentedTextData = await fetchSegmentedText(text);
        setSegmentedText(segmentedTextData);
        setDrawerVisible(false); // Close drawer after analyzing
    };

    const showDrawer = () => {
        setDrawerVisible(true);
    };

    const closeDrawer = () => {
        setDrawerVisible(false);
    };

    return (
        <Layout>
            <NavigationHeader selectedKey={"Text-Analyzer"} />
            <Content style={{ padding: '24px 48px' }}>
                <Layout>
                    <Space size="middle" style={{ marginBottom: '16px' }}>
                        <Button
                            type="primary"
                            style={{
                                borderRadius: '4px',
                            }}
                            onClick={showDrawer}
                        >
                            Analyze Text
                        </Button>
                        <Button
                            type="default"
                            icon={<SearchOutlined />} // Add magnifying glass icon
                            style={{
                                borderRadius: '4px',
                            }}
                        >
                            Nouns
                        </Button>
                        <Button
                            type="default"
                            icon={<SearchOutlined />} // Add magnifying glass icon
                            style={{
                                borderRadius: '4px',
                            }}
                        >
                            Verbs
                        </Button>
                        <Button
                            type="default"
                            icon={<SearchOutlined />} // Add magnifying glass icon
                            style={{
                                borderRadius: '4px',
                            }}
                        >
                            Adjectives
                        </Button>
                    </Space>

                    <Content style={{
                        background: 'white',
                        display: 'flex',
                        width: "100%",
                    }}>
                        <Row gutter={[16, 16]} style={{ width: '100%' }}>
                            <Col xs={24} lg={12} style={{ display: 'flex', flexDirection: 'column', padding: '24px' }}>
                                <Space direction={"vertical"} size={0} style={{
                                    display: "flex",
                                    flexGrow: 1,
                                    height: "100%",
                                    overflowY: "scroll"
                                }}>
                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        {segmentedText.length > 0 ? segmentedText.map((segment, index) => (
                                            <Button
                                                style={{
                                                    marginRight: '10px',
                                                    marginBottom: '10px',
                                                    fontSize: 'larger',
                                                    padding: 0,
                                                }}
                                                key={index}
                                                type="text"
                                                onClick={() => handleLookup(segment)}
                                            >
                                                {segment}
                                            </Button>
                                        )) : (
                                            <Empty
                                                style={{ width: '100%' }}
                                                description="No Text To Analyze. Start by entering some text."
                                            />
                                        )}
                                    </div>
                                </Space>
                            </Col>
                            <Col xs={24} lg={12} style={{ padding: '0 24px', overflowY: "scroll" }}>
                                <DictionaryTabView entries={entries} onRemove={handleRemoveEntry} />
                            </Col>
                        </Row>
                    </Content>
                </Layout>

                <Drawer
                    title="Text Submission"
                    placement="left"
                    width={400}
                    onClose={closeDrawer}
                    visible={drawerVisible}
                >
                    <Space direction={"vertical"} style={{ width: "100%" }}>
                        <TextArea
                            placeholder="Paste or type text here"
                            autoSize={{ maxRows: 10 }}
                            style={{ width: "100%" }}
                            value={text}
                            onChange={handleTextChange}
                        />
                        <Button type="primary" style={{ width: "50%" }} onClick={handleTextSubmission}>
                            Analyze
                        </Button>
                    </Space>
                </Drawer>
            </Content>
        </Layout>
    );
};

export default TextAnalyzer;
