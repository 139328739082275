import React, {useEffect, useRef, useState} from "react";
import {Tabs, theme} from "antd";
import DictionaryEntryCardView from "./DictionaryEntryCardView";
import {defaultDictionaryEntry, DictionaryEntry} from "../../interfaces/DictionaryEntry";

const { useToken } = theme;

type TargetKey = React.MouseEvent | React.KeyboardEvent | string;

const defaultPanes = [
    {
        label: defaultDictionaryEntry.simplified,
        children:
            <DictionaryEntryCardView
                entry={defaultDictionaryEntry}
            />,
        key: '0'
    },
];

interface DictionaryTabViewProps {
    entries: DictionaryEntry[]

    onRemove: (targetIndex: number) => void;

}

const DictionaryTabView: React.FC<DictionaryTabViewProps> = ({ entries, onRemove}) => {
    const { token } = useToken();
    const [activeKey, setActiveKey] = useState(defaultPanes[0].key);
    const [items, setItems] = useState(defaultPanes);
    const newTabIndex = useRef(0);

    const onTabChange = (key: string) => {
        setActiveKey(key);
    };

    useEffect(() => {
        if (entries.length > 0) {
            const initialPanes = entries.map((entry, index) => ({
                label: entry.simplified,
                children: (
                    <DictionaryEntryCardView
                        entry={entry}
                    />
                ),
                key: `tab_${index}`
            }));
            setItems(initialPanes);
            setActiveKey(initialPanes[initialPanes.length-1].key);
        }
    }, [entries]);

    const add = () => {
        const newActiveKey = `newTab${newTabIndex.current++}`;
        setItems([...items, { label: 'New Tab',  children:
                <DictionaryEntryCardView
                    entry={{
                        traditional: "",
                        simplified: "",
                        pinyin: "",
                        jyutping: "",
                        definition: "",
                        characterEntries: [],
                        exampleSentences: [],
                    }}
                />, key: newActiveKey }]);
        setActiveKey(newActiveKey);
    };


    const remove = (targetKey: TargetKey) => {
        const targetIndex = items.findIndex((pane) => pane.key === targetKey);
        onRemove(targetIndex)
        const newPanes = items.filter((pane) => pane.key !== targetKey);
        if (newPanes.length && targetKey === activeKey) {
            const { key } = newPanes[targetIndex === newPanes.length ? targetIndex - 1 : targetIndex];
            setActiveKey(key);
        }
        setItems(newPanes);
    };

    const onEdit = (targetKey: TargetKey, action: 'add' | 'remove') => {
        if (action === 'add') {
            add();
        } else {
            remove(targetKey);
        }
    };

    return (
        <Tabs
            hideAdd
            onChange={onTabChange}
            activeKey={activeKey}
            type="editable-card"
            onEdit={onEdit}
            items={items}
            style={{padding: '12px 0'}}
            animated={true}
        />
    )
}
export default DictionaryTabView;