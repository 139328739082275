import React from "react";
import { DictionaryEntry } from "../../interfaces/DictionaryEntry";
import { List, Space } from "antd";
import CharacterStrokeOrder from "./CharacterStrokeOrder";
import {SentencePair} from "../../interfaces/SentencePair";

interface DictionaryEntryCardViewProps {
    entry: DictionaryEntry;
}

const alternateColors = ['#FF5733', '#3498DB']; // Two alternating colors

// Helper function to check if a character is punctuation
const isPunctuation = (char: string): boolean => {
    const punctuation = " ,.!?，。！？：；（）《》“”‘’";
    return punctuation.includes(char);
};

// Helper function to apply color to the Pinyin sentence based on the color run lengths, excluding spaces and punctuation
const applyColorToPinyin = (pinyin: string, colorRunLengths: number[]): JSX.Element[] => {
    const coloredPinyin: JSX.Element[] = [];
    let currentIndex = 0; // Tracks the index in the pinyin string
    let currentColorIndex = 0; // Tracks the alternating colors
    let runLengthIndex = 0; // Tracks the current color run length

    for (let i = 0; i < pinyin.length; i++) {
        const currentChar = pinyin[i];

        if (isPunctuation(currentChar) || currentChar === ' ') {
            // If the character is punctuation or a space, keep it black and don't increment color runs
            coloredPinyin.push(
                <span key={i} style={{ color: 'black' }}>
                    {currentChar}
                </span>
            );
        } else {
            // Apply color to non-punctuation characters according to the color run lengths
            if (runLengthIndex < colorRunLengths.length) {
                const runLength = colorRunLengths[runLengthIndex];
                const endOfRun = currentIndex + runLength;

                // Extract the current segment of the Pinyin string (within the color run length)
                const segment = pinyin.slice(i, Math.min(endOfRun, pinyin.length));
                coloredPinyin.push(
                    <span key={i} style={{ color: alternateColors[currentColorIndex % alternateColors.length] }}>
                        {segment}
                    </span>
                );

                // Update the index and run length
                i += segment.length - 1; // Move to the next segment
                currentIndex += runLength;
                currentColorIndex++;
                runLengthIndex++;
            }
        }
    }

    return coloredPinyin;
};

const DictionaryEntryCardView: React.FC<DictionaryEntryCardViewProps> = ({ entry }) => {
    return (
        <Space direction={"vertical"} size={0} style={{ width: "100%", height: "100%" }}>
            <Space align={"start"} size={0} style={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
                <Space direction={"vertical"} size={0} style={{ width: 300, padding: '0 12px 0 0' }}>
                    <p><strong>Simplified:</strong> {entry.simplified}</p>
                    <p><strong>Traditional:</strong> {entry.traditional}</p>
                    <p><strong>Pinyin:</strong> {entry.pinyin}</p>
                    <p><strong>Jyutping:</strong> {entry.jyutping}</p>
                    <p style={{ wordBreak: 'break-word' }}>
                        <strong>Definitions:</strong> {entry.definition}
                    </p>
                </Space>
                <Space direction={"horizontal"} style={{ display: "flex", flexWrap: "wrap", flexGrow: 1 }} align={"start"}>
                    {entry.characterEntries.length > 0 ? entry.characterEntries.map((subEntry, index) => (
                            <Space direction={"vertical"} key={index} style={{ width: 100, height: 250, overflow: 'hidden' }}>
                                <CharacterStrokeOrder character={entry.simplified.charAt(index)} />
                                <p style={{ margin: 0, padding: 0 }}>{subEntry.pinyin || ""}</p>
                                <p
                                    style={{
                                        margin: 0,
                                        padding: 0,
                                        wordBreak: 'break-word',
                                        overflow: 'hidden',
                                        display: '-webkit-box',
                                        WebkitBoxOrient: 'vertical',
                                        WebkitLineClamp: 3,
                                        fontSize: "small"
                                    }}
                                >
                                    {subEntry.definition}
                                </p>
                            </Space>
                        )) :
                        <Space direction={"vertical"} style={{ width: 100, height: 250, overflow: 'hidden' }}>
                            <CharacterStrokeOrder character={entry.simplified} />
                            <p style={{ margin: 0, padding: 0 }}>{entry.pinyin || ""}</p>
                            <p
                                style={{
                                    margin: 0,
                                    padding: 0,
                                    wordBreak: 'break-word',
                                    overflow: 'hidden',
                                    display: '-webkit-box',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 3,
                                    fontSize: "small"
                                }}
                            >
                                {entry.definition}
                            </p>
                        </Space>
                    }
                </Space>
            </Space>
            <List
                header={<strong>Example Sentences (例句)</strong>}
                itemLayout="horizontal"
                dataSource={entry.exampleSentences}
                locale={{ emptyText: 'No examples found' }}
                renderItem={(item: SentencePair, index) => (
                    <List.Item key={index}>
                        <Space direction={"vertical"} size={0}>
                            <Space direction={"vertical"} size={0}>
                                <p style={{ fontSize: "smaller" }}>Chinese Sentence</p>
                                <p>{item.chinese_sentence}</p>
                                <p>{applyColorToPinyin(item.pinyin_sentence, item.color_run_length)}</p> {/* Apply color to Pinyin */}
                            </Space>

                            <Space direction={"vertical"} size={0}>
                                <p style={{ fontSize: "smaller" }}>English Sentence</p>
                                <p>{item.english_sentence}</p>
                            </Space>
                        </Space>
                    </List.Item>
                )}
            />
        </Space>
    );
};

export default DictionaryEntryCardView;
