import {SentencePair} from "./SentencePair";

export interface DictionaryEntry {
    traditional: string;
    simplified: string;
    pinyin: string;
    jyutping: string;
    definition: string;
    characterEntries: DictionaryEntry[];
    exampleSentences: SentencePair[];
}

export const defaultDictionaryEntry: DictionaryEntry = {
    traditional: "歡迎光臨",
    simplified: "欢迎光临",
    pinyin: "huānyíng guānglín",
    jyutping: "",
    definition: "welcome",
    characterEntries: [
        {
            traditional: "歡",
            simplified: "欢",
            pinyin: "huān",
            jyutping: "fun1",
            definition: "joyous",
            characterEntries: [],
            exampleSentences: [],
        },
        {
            traditional: "迎",
            simplified: "迎",
            pinyin: "yíng",
            jyutping: "ying2",
            definition: "welcome",
            characterEntries: [],
            exampleSentences: [],
        },
        {
            traditional: "光",
            simplified: "光",
            pinyin: "guāng",
            jyutping: "guang1",
            definition: "light",
            characterEntries: [],
            exampleSentences: [],


        },
        {
            traditional: "臨",
            simplified: "临",
            pinyin: "lín",
            jyutping: "lam6",
            definition: "arrive",
            characterEntries: [],
            exampleSentences: [],

        }
    ],
    exampleSentences: [],

}
