import React, { useEffect, useState } from "react";
import { AutoComplete, Button, Space, Typography, Row, Col } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import axios from "axios";

const mockVal = (str: string, repeat = 1) => ({
    value: str.repeat(repeat),
});

interface SidebarSearchViewProps {
    onLookup: (word: string) => void;
}

const SidebarSearchView: React.FC<SidebarSearchViewProps> = ({ onLookup }) => {
    const [value, setValue] = useState<string>(""); // Set value as a string
    const [options, setOptions] = useState<{ value: string }[]>([]);
    const [recentSearches, setRecentSearches] = useState<string[]>([]);
    const [trendingSearches, setTrendingSearches] = useState<string[]>(["何方", "在这", "美国"]);
    const [randomWords, setRandomWords] = useState<string[]>(["屏幕", "车门", "脉络"]);
    const [selectedIndex, setSelectedIndex] = useState<number>(0); // Track selected option index

    useEffect(() => {
        const storedSearches = localStorage.getItem("recentSearches");
        if (storedSearches) {
            setRecentSearches(JSON.parse(storedSearches));
        }
    }, []);

    const saveSearchTerm = (term: string) => {
        let searches = recentSearches;
        if (!searches.includes(term)) {
            searches = [term, ...searches].slice(0, 20);
            setRecentSearches(searches);
            localStorage.setItem("recentSearches", JSON.stringify(searches));
        }
    };

    const onSelect = (data: string) => {
        onLookup(data);
        saveSearchTerm(data);
        setValue(""); // Clear input after selecting a word
        setOptions([]); // Clear suggestions
    };

    const onChange = (data: string) => {
        setValue(data); // Update input field with new value
        setSelectedIndex(0); // Reset the index when value changes
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Tab' && options.length > 0) {
            e.preventDefault(); // Prevent default tab behavior
            const nextIndex = (selectedIndex + 1) % options.length; // Cycle through options
            setSelectedIndex(nextIndex);
            setValue(options[nextIndex].value); // Set input value to the next suggestion (string)
        }
    };

    const handleSearch = () => {
        if (value.trim()) {
            onLookup(value);
            saveSearchTerm(value);
            setValue(""); // Clear input after search
            setOptions([]); // Clear suggestions
        }
    };

    const fetchAutocompleteResults = async (searchText: string) => {
        const cleanedText = searchText.replace(/\s+/g, "");
        if (cleanedText) {
            try {
                const response = await axios.get(`http://localhost:8080/dictionary-service/autocomplete?substring=${cleanedText}`);
                const autocompleteOptions = response.data.map((word: string) => ({ value: word }));
                setOptions(autocompleteOptions);
            } catch (error) {
                console.error("Error fetching autocomplete results:", error);
            }
        }
    };

    // Helper function to map items into three columns
    const mapToColumns = (items: string[], columns: number) => {
        const columnData: string[][] = Array.from({ length: columns }, () => []);
        items.forEach((item, index) => {
            columnData[index % columns].push(item); // Distribute items column-wise
        });
        return columnData;
    };

    return (
        <Space direction={"vertical"} style={{ width: "100%" }}>
            <Space.Compact style={{ width: "100%" }}>
                <AutoComplete
                    options={options}
                    style={{ width: "85%" }}
                    onSelect={onSelect}
                    onSearch={(text) => fetchAutocompleteResults(text)}
                    onChange={onChange}
                    value={value} // Value must be a string
                    placeholder="input here / 输入"
                    onKeyDown={handleKeyDown} // Add keydown event handler for cycling options
                    open={!!options.length} // Show dropdown only if there are options
                />
                <Button
                    type="primary"
                    icon={<SearchOutlined />}
                    onClick={handleSearch}
                />
            </Space.Compact>

            <Space direction={"vertical"} style={{ padding: "12px 0" }}>
                <Typography>Recent Searches (最近搜索)</Typography>
                <Row gutter={[8, 8]}>
                    {mapToColumns(recentSearches, 3).map((column, colIndex) => (
                        <Col span={8} key={colIndex}>
                            {column.map((search, index) => (
                                <Button key={index} style={{ color: "gray", cursor: "pointer", display: "block" }} onClick={() => onLookup(search)}>
                                    {search.length > 3 ? search.slice(0, 3) + ".." : search}
                                </Button>
                            ))}
                        </Col>
                    ))}
                </Row>

                <Typography>Trending Searches (热门搜索)</Typography>
                <Row gutter={[8, 8]}>
                    {mapToColumns(trendingSearches, 3).map((column, colIndex) => (
                        <Col span={8} key={colIndex}>
                            {column.map((search, index) => (
                                <Button key={index} style={{ color: "gray", cursor: "pointer", display: "block" }} onClick={() => onLookup(search)}>
                                    {search}
                                </Button>
                            ))}
                        </Col>
                    ))}
                </Row>

                <Typography>Random Words (随机词汇)</Typography>
                <Row gutter={[8, 8]}>
                    {mapToColumns(randomWords, 3).map((column, colIndex) => (
                        <Col span={8} key={colIndex}>
                            {column.map((search, index) => (
                                <Button key={index} style={{ color: "gray", cursor: "pointer", display: "block" }} onClick={() => onLookup(search)}>
                                    {search}
                                </Button>
                            ))}
                        </Col>
                    ))}
                </Row>
            </Space>
        </Space>
    );
};

export default SidebarSearchView;
