import React, {useEffect, useState} from "react";
import { Layout, Menu, Space, theme, Typography } from "antd";
import { useNavigate } from "react-router-dom";

const { useToken } = theme;
const { Header } = Layout;
const { Title } = Typography;

interface NavigationHeaderProps {
    selectedKey: string
}

const NavigationHeader: React.FC<NavigationHeaderProps> = ({ selectedKey }) => {
    const { token } = useToken();
    const navigate = useNavigate();

    const handleMenuClick = (e: any) => {
        switch (e.key) {
            case 'Dictionary':
                navigate('/dictionary');
                break;
            case 'Text-Analyzer':
                navigate('/text-analyzer');
                break;
            case 'Common-Characters':
                navigate('/characters');
                break;
            case 'Idioms':
                navigate('/idioms');
                break;
            case 'Vocabulary-Sets':
                navigate('/vocabulary-sets');
                break;
            default:
                break;
        }
    };

    return (
        <Header style={{ backgroundColor: token.colorPrimary, width: "100%", height: "fit-content", position: "sticky", top: 0, zIndex: 1}}>
            <Title level={3} style={{ color: 'white', margin: 0 }}>
                Modern Chinese Dictionary
            </Title>
            <Menu
                theme="light"
                mode="horizontal"
                selectedKeys={[selectedKey]}
                onClick={handleMenuClick}
            >
                <Menu.Item key="Dictionary">Dictionary (词典)</Menu.Item>
                <Menu.Item key="Text-Analyzer">Text Analyzer (分词)</Menu.Item>
                <Menu.Item key="Common-Characters">Common Characters (常用字)</Menu.Item>
                <Menu.Item key="Idioms">Idioms （成语）</Menu.Item>
                <Menu.Item key="Vocabulary-Sets">Vocabulary Sets (词汇集)</Menu.Item>
            </Menu>
        </Header>
    );
}

export default NavigationHeader;
