import React, {useEffect, useState} from "react";
import NavigationHeader from "../../components/NavigationHeader";
import {Content} from "antd/es/layout/layout";
import {Layout, Space, Spin, Table, TableColumnsType, TablePaginationConfig, theme, Checkbox} from "antd";
import {Character} from "../../interfaces/Character";
import {fetchCharacterPageData} from "../../services/characterService";
import {Page} from "../../interfaces/Page";

const { useToken } = theme;

// Helper function to get learned status from localStorage
const getLearnedStatus = (id: number): boolean => {
    const storedStatus = localStorage.getItem(`character_${id}`);
    return storedStatus === 'true';  // Default to false if not set
};

// Helper function to save learned status to localStorage
const setLearnedStatus = (id: number, isLearned: boolean) => {
    localStorage.setItem(`character_${id}`, isLearned.toString());
};

const CharactersPage: React.FC = () => {
    const { token } = useToken();
    const [loading, setLoading] = useState(false);  // Bool to track if table is ready to see
    const [characters, setCharacters] = useState<Character[]>([]);  // Data Source container
    const [learnedStatus, setLearnedStatusState] = useState<{[key: number]: boolean}>({}); // State for learned status

    // Initial Page configuration, updated on initial page load and future table changes
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        pageSize: 100,
        total: 0,
    });

    /**
     * Initial call to fetch pagination data like total table size
     */
    useEffect(() => {
        const initPaginationData = async () => {
            setLoading(true);
            try {
                const initialPageData: Page<Character> = await fetchCharacterPageData(1, 100);
                const learnedData = initialPageData.content.reduce((acc: any, character: Character) => {
                    acc[character.id] = getLearnedStatus(character.id);
                    return acc;
                }, {});

                setCharacters(initialPageData.content);
                setLearnedStatusState(learnedData);
                setPagination({
                    current: initialPageData.number + 1,
                    pageSize: initialPageData.size,
                    total: initialPageData.totalElements,
                });
            } catch (error) {
                console.error('Failed to fetch initial page data:', error);
            } finally {
                setLoading(false);
            }
        };

        void initPaginationData();
    }, []);


    /**
     * Fetches next page of characters from server
     * @param pageNumber page to look for
     * @param pageSize number of entries to retrieve
     */
    const fetchNextCharacterPage = async (pageNumber: number, pageSize: number) => {
        setLoading(true);
        try {
            const characterPageData = await fetchCharacterPageData(pageNumber, pageSize);
            const learnedData = characterPageData.content.reduce((acc: any, character: Character) => {
                acc[character.id] = getLearnedStatus(character.id);
                return acc;
            }, {});

            setCharacters(characterPageData.content);
            setLearnedStatusState(learnedData);
        } catch (error) {
            console.error('Failed to fetch data:', error);
        } finally {
            setLoading(false);
        }
    };

    /**
     * Handles action when user uses bottom page navigation buttons
     * @param newPagination new page data to use
     */
    const handleTableChange = (newPagination: TablePaginationConfig) => {
        void fetchNextCharacterPage(newPagination.current || 1, newPagination.pageSize || 100);
        setPagination(newPagination);
    };

    /**
     * Toggles the learned state of a character and updates local state and localStorage
     */
    const handleCheckboxChange = (id: number, checked: boolean) => {
        setLearnedStatus(id, checked);
        setLearnedStatusState((prevState) => ({
            ...prevState,
            [id]: checked
        }));
    };

    // Columns definition including a new checkbox column
    const columns: TableColumnsType<Character> = [
        {
            title: 'Index',
            dataIndex: 'id',
            width: 60,
        },
        {
            title: 'Simplified',
            dataIndex: 'simplified',
            width: 90,
        },
        {
            title: 'Traditional',
            dataIndex: 'traditional',
            width: 90,
        },
        {
            title: 'Pinyin',
            dataIndex: 'pinyin',
            width: 100,
        },
        {
            title: 'Definition',
            dataIndex: 'definition',
            width: 200,
        },
        {
            title: 'Learned',
            dataIndex: 'learned',
            render: (_, record) => (
                <Checkbox
                    style={{ transform: 'scale(1.5)', padding: '8px' }}  // Increase the size of the checkbox
                    checked={learnedStatus[record.id] || false}
                    onChange={(e) => handleCheckboxChange(record.id, e.target.checked)}
                />
            ),
            width: 80,  // Adjust width for bigger checkbox
        },
    ];

    return (
        <Layout>
            <NavigationHeader selectedKey={'Common-Characters'} />
            <Content style={{ padding: '24px 48px' }}>
                <Layout
                    style={{ padding: '0px 24px', background: token.colorBgContainer, borderRadius: token.borderRadiusLG }}
                >
                    <Content style={{ padding: '24px 0px', minHeight: 600, background: 'white'}}>
                        <Spin spinning={loading}>
                            <Table
                                columns={columns}
                                dataSource={characters}
                                pagination={pagination}
                                onChange={handleTableChange}
                                scroll={{ y: 400}}
                            />
                        </Spin>
                    </Content>
                </Layout>
            </Content>
        </Layout>
    )
}

export default CharactersPage;
