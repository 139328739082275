import React, {useEffect, useState} from "react";
import NavigationHeader from "../../components/NavigationHeader";
import {Content} from "antd/es/layout/layout";
import {Layout, Space, Table, TableColumnsType, theme} from "antd";
import {Idiom} from "../../interfaces/Idiom";
import {fetchIdioms} from "../../services/idiomService";

const { useToken } = theme;

const columns: TableColumnsType<Idiom> = [
    {
        title: 'Index',
        dataIndex: 'id',
        width: 60,
    },
    {
        title: 'Simplified',
        dataIndex: 'simplified',
        width: 90,
    },
    {
        title: 'Traditional',
        dataIndex: 'traditional',
        width: 90,
    },
    {
        title: 'Pinyin',
        dataIndex: 'pinyin',
        width: 100,
    },
    {
        title: 'Definition',
        dataIndex: 'definition',
        width: 200,
    }
]

const IdiomsPage: React.FC = () => {
    const { token } = useToken();

    const [idioms, setIdioms] = useState<Idiom[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const idiomsData = await fetchIdioms();
                setIdioms(idiomsData);

            } catch (error) {
                console.error('Error fetching characters:', error);
                // Handle error as needed
            }
        };
        fetchData();

    }, []); // Dependency array includes pageNumber and pageSize



    return (
        <Layout>
            <NavigationHeader selectedKey={'Idioms'} />
            <Content style={{ padding: '24px 48px' }}>
                <Layout
                    style={{ padding: '0px 24px', background: token.colorBgContainer, borderRadius: token.borderRadiusLG }}
                >
                    <Content style={{ padding: '24px 0px', minHeight: 600, background: 'white'}}>
                        <Table columns={columns} dataSource={idioms} pagination={{ pageSize: 100 }} scroll={{ y: 400}} />
                    </Content>
                </Layout>
            </Content>
        </Layout>
    )
}

export default IdiomsPage;