import { languageToolApi } from "./clientConfiguration";
import {SentencePairResponse} from "../interfaces/SentencePairResponse";

interface SegmentResponse {
    message: string[];
}

export const fetchSegmentedText = async (text: string): Promise<string[]> => {
    try {
        const endpoint = 'language-tools/segment/';
        const response = await languageToolApi.post<SegmentResponse>(endpoint, { text });
        return response.data.message;
    } catch (error) {
        console.error('Error:', error);
        return [];
    }
};

export const fetchExampleSentences = async (word: string): Promise<SentencePairResponse> => {
    try {
        const endpoint = `dictionary-tools/example-sentences/?word=${encodeURIComponent(word)}`;
        const response = await languageToolApi.get<SentencePairResponse>(endpoint);
        return response.data;
    } catch (error) {
        console.error('Error:', error);
        return { sentence_pairs: []};
    }
};
